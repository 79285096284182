/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { FileFragmentDoc, AddressFragmentDoc, CustomAddressFragmentDoc } from './CoreFragments.generated';
import { CampaignFragmentDoc } from './CampaignFragments.generated';
import { ArticlePreviewFragmentDoc } from './BlogFragments.generated';
export const KnowledgebaseTagFragmentDoc = gql`
    fragment KnowledgebaseTag on KnowledgebaseTag {
  id
  name
}
    `;
export const KnowledgebaseCategoryPreviewFragmentDoc = gql`
    fragment KnowledgebaseCategoryPreview on KnowledgebaseCategoryPreview {
  id
  name
  description
  parents {
    id
    name
  }
  vertical
  subVertical
}
    `;
export const KnowledgebaseQuestionPreviewFragmentDoc = gql`
    fragment KnowledgebaseQuestionPreview on KnowledgebaseQuestionPreview {
  id
  title
  seoName
  perex
  type
}
    `;
export const ContentSectionJumbotronFragmentDoc = gql`
    fragment ContentSectionJumbotron on ContentSectionJumbotron {
  id
  order
  sectionType
  contentSerialized
  subtitle
  title
  type
  btnText: buttonText
  btnUrl: buttonUrl
  campaign {
    ...Campaign
  }
  metaDescriptionAsSubtitle
  callbackSource
}
    ${CampaignFragmentDoc}`;
export const ContentSectionBlogArticlesFragmentDoc = gql`
    fragment ContentSectionBlogArticles on ContentSectionBlogArticles {
  id
  order
  sectionType
  articles {
    ...ArticlePreview
  }
}
    ${ArticlePreviewFragmentDoc}`;
export const ContentSectionContactsFragmentDoc = gql`
    fragment ContentSectionContacts on ContentSectionContacts {
  id
  order
  sectionType
}
    `;
export const ContentSectionUspFragmentDoc = gql`
    fragment ContentSectionUsp on ContentSectionUsp {
  id
  order
  sectionType
}
    `;
export const ContentSectionTextFragmentDoc = gql`
    fragment ContentSectionText on ContentSectionText {
  id
  order
  sectionType
  contentSerialized
}
    `;
export const ContentSectionKnowledgeBaseConceptsFragmentDoc = gql`
    fragment ContentSectionKnowledgeBaseConcepts on ContentSectionKnowledgeBaseConcepts {
  id
  order
  sectionType
  knowledbaseTitle: title
  questions {
    ...KnowledgebaseQuestionPreview
  }
}
    ${KnowledgebaseQuestionPreviewFragmentDoc}`;
export const ContentSectionBannerWithButtonFragmentDoc = gql`
    fragment ContentSectionBannerWithButton on ContentSectionBannerWithButton {
  id
  order
  sectionType
  contentSerialized
  title
  buttonText
  buttonUrl
  campaign {
    ...Campaign
  }
  bannerType: type
}
    ${CampaignFragmentDoc}`;
export const ContentSectionBannerWithCallbackButtonFragmentDoc = gql`
    fragment ContentSectionBannerWithCallbackButton on ContentSectionBannerWithCallbackButton {
  id
  order
  sectionType
  contentSerialized
  title
  buttonText
  campaign {
    ...Campaign
  }
  callbackSource
  popupTitle
  popupSubTitle
}
    ${CampaignFragmentDoc}`;
export const ContentSectionTableFragmentDoc = gql`
    fragment ContentSectionTable on ContentSectionTable {
  id
  order
  sectionType
  content
  rows
  columns
}
    `;
export const ContentSectionVehicleInsuranceContractDismissalDateFormFragmentDoc = gql`
    fragment ContentSectionVehicleInsuranceContractDismissalDateForm on ContentSectionVehicleInsuranceContractDismissalDateForm {
  id
  order
  title
  sectionType
  contentSerialized
}
    `;
export const KnowledgebaseQuestionDetailFragmentDoc = gql`
    fragment KnowledgebaseQuestionDetail on KnowledgebaseQuestionDetail {
  id
  title
  titleSuffix
  seoName
  metaTitle
  metaDescription
  metaImage {
    ...File
  }
  perex
  category {
    ...KnowledgebaseCategoryPreview
  }
  similar {
    ...KnowledgebaseQuestionPreview
  }
  publicVisible
  publishedAt
  type
  vertical
  subVertical
  redirectTo
  sections {
    ... on ContentSectionJumbotron {
      ...ContentSectionJumbotron
    }
    ... on ContentSectionBlogArticles {
      ...ContentSectionBlogArticles
    }
    ... on ContentSectionContacts {
      ...ContentSectionContacts
    }
    ... on ContentSectionUsp {
      ...ContentSectionUsp
    }
    ... on ContentSectionText {
      ...ContentSectionText
    }
    ... on ContentSectionKnowledgeBaseConcepts {
      ...ContentSectionKnowledgeBaseConcepts
    }
    ... on ContentSectionBannerWithButton {
      ...ContentSectionBannerWithButton
    }
    ... on ContentSectionBannerWithCallbackButton {
      ...ContentSectionBannerWithCallbackButton
    }
    ... on ContentSectionTable {
      ...ContentSectionTable
    }
    ... on ContentSectionVehicleInsuranceContractDismissalDateForm {
      ...ContentSectionVehicleInsuranceContractDismissalDateForm
    }
  }
}
    ${FileFragmentDoc}
${KnowledgebaseCategoryPreviewFragmentDoc}
${KnowledgebaseQuestionPreviewFragmentDoc}
${ContentSectionJumbotronFragmentDoc}
${ContentSectionBlogArticlesFragmentDoc}
${ContentSectionContactsFragmentDoc}
${ContentSectionUspFragmentDoc}
${ContentSectionTextFragmentDoc}
${ContentSectionKnowledgeBaseConceptsFragmentDoc}
${ContentSectionBannerWithButtonFragmentDoc}
${ContentSectionBannerWithCallbackButtonFragmentDoc}
${ContentSectionTableFragmentDoc}
${ContentSectionVehicleInsuranceContractDismissalDateFormFragmentDoc}`;
export const KnowledgebaseCategoryFragmentDoc = gql`
    fragment KnowledgebaseCategory on KnowledgebaseCategory {
  id
  name
  titleSuffix
  description
  metaTitle
  metaDescription
  metaImage {
    ...File
  }
  parents {
    id
    name
  }
  subCategories {
    ...KnowledgebaseCategoryPreview
  }
  questions {
    ...KnowledgebaseQuestionPreview
  }
  vertical
  subVertical
}
    ${FileFragmentDoc}
${KnowledgebaseCategoryPreviewFragmentDoc}
${KnowledgebaseQuestionPreviewFragmentDoc}`;
export const KnowledgebaseQuestionPreviewWithContentFragmentDoc = gql`
    fragment KnowledgebaseQuestionPreviewWithContent on KnowledgebaseQuestionPreviewWithContent {
  id
  title
  seoName
  perex
  type
  sections {
    ... on ContentSectionJumbotron {
      ...ContentSectionJumbotron
    }
    ... on ContentSectionBlogArticles {
      ...ContentSectionBlogArticles
    }
    ... on ContentSectionContacts {
      ...ContentSectionContacts
    }
    ... on ContentSectionUsp {
      ...ContentSectionUsp
    }
    ... on ContentSectionText {
      ...ContentSectionText
    }
    ... on ContentSectionKnowledgeBaseConcepts {
      ...ContentSectionKnowledgeBaseConcepts
    }
    ... on ContentSectionBannerWithButton {
      ...ContentSectionBannerWithButton
    }
    ... on ContentSectionBannerWithCallbackButton {
      ...ContentSectionBannerWithCallbackButton
    }
    ... on ContentSectionTable {
      ...ContentSectionTable
    }
    ... on ContentSectionVehicleInsuranceContractDismissalDateForm {
      ...ContentSectionVehicleInsuranceContractDismissalDateForm
    }
  }
}
    ${ContentSectionJumbotronFragmentDoc}
${ContentSectionBlogArticlesFragmentDoc}
${ContentSectionContactsFragmentDoc}
${ContentSectionUspFragmentDoc}
${ContentSectionTextFragmentDoc}
${ContentSectionKnowledgeBaseConceptsFragmentDoc}
${ContentSectionBannerWithButtonFragmentDoc}
${ContentSectionBannerWithCallbackButtonFragmentDoc}
${ContentSectionTableFragmentDoc}
${ContentSectionVehicleInsuranceContractDismissalDateFormFragmentDoc}`;
export const ContentSectionKnowledgeBaseQuestionsFragmentDoc = gql`
    fragment ContentSectionKnowledgeBaseQuestions on ContentSectionKnowledgeBaseQuestions {
  id
  order
  sectionType
  knowledbaseTitle: title
  questions {
    ...KnowledgebaseQuestionPreview
  }
  priorityQuestion1 {
    ...KnowledgebaseQuestionPreviewWithContent
  }
  priorityQuestion2 {
    ...KnowledgebaseQuestionPreviewWithContent
  }
}
    ${KnowledgebaseQuestionPreviewFragmentDoc}
${KnowledgebaseQuestionPreviewWithContentFragmentDoc}`;
export const ContentSectionFinanceProductsFragmentDoc = gql`
    fragment ContentSectionFinanceProducts on ContentSectionFinanceProducts {
  id
  order
  sectionType
  titleWhite
  titleYellow
  text
  productTypes
  productPurpose
}
    `;
export const ContentSectionPrimaryHeadlineFragmentDoc = gql`
    fragment ContentSectionPrimaryHeadline on ContentSectionPrimaryHeadline {
  id
  order
  sectionType
  primaryHeadline
}
    `;
export const ContentSectionInternetSpeedFragmentDoc = gql`
    fragment ContentSectionInternetSpeed on ContentSectionInternetSpeed {
  id
  order
  title
  sectionType
  contentSerialized
}
    `;
export const ContentSectionFragmentDoc = gql`
    fragment ContentSection on ContentSection {
  ... on ContentSectionJumbotron {
    ...ContentSectionJumbotron
  }
  ... on ContentSectionBlogArticles {
    ...ContentSectionBlogArticles
  }
  ... on ContentSectionContacts {
    ...ContentSectionContacts
  }
  ... on ContentSectionUsp {
    ...ContentSectionUsp
  }
  ... on ContentSectionText {
    ...ContentSectionText
  }
  ... on ContentSectionKnowledgeBaseConcepts {
    ...ContentSectionKnowledgeBaseConcepts
  }
  ... on ContentSectionKnowledgeBaseQuestions {
    ...ContentSectionKnowledgeBaseQuestions
  }
  ... on ContentSectionBannerWithButton {
    ...ContentSectionBannerWithButton
  }
  ... on ContentSectionBannerWithCallbackButton {
    ...ContentSectionBannerWithCallbackButton
  }
  ... on ContentSectionTable {
    ...ContentSectionTable
  }
  ... on ContentSectionVehicleInsuranceContractDismissalDateForm {
    ...ContentSectionVehicleInsuranceContractDismissalDateForm
  }
  ... on ContentSectionFinanceProducts {
    ...ContentSectionFinanceProducts
  }
  ... on ContentSectionPrimaryHeadline {
    ...ContentSectionPrimaryHeadline
  }
  ... on ContentSectionInternetSpeed {
    ...ContentSectionInternetSpeed
  }
}
    ${ContentSectionJumbotronFragmentDoc}
${ContentSectionBlogArticlesFragmentDoc}
${ContentSectionContactsFragmentDoc}
${ContentSectionUspFragmentDoc}
${ContentSectionTextFragmentDoc}
${ContentSectionKnowledgeBaseConceptsFragmentDoc}
${ContentSectionKnowledgeBaseQuestionsFragmentDoc}
${ContentSectionBannerWithButtonFragmentDoc}
${ContentSectionBannerWithCallbackButtonFragmentDoc}
${ContentSectionTableFragmentDoc}
${ContentSectionVehicleInsuranceContractDismissalDateFormFragmentDoc}
${ContentSectionFinanceProductsFragmentDoc}
${ContentSectionPrimaryHeadlineFragmentDoc}
${ContentSectionInternetSpeedFragmentDoc}`;