/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { FileFragmentDoc, AddressFragmentDoc, CustomAddressFragmentDoc } from './CoreFragments.generated';
import { CampaignFragmentDoc } from './CampaignFragments.generated';
export const ArticlePreviewFragmentDoc = gql`
    fragment ArticlePreview on ArticlePreview {
  id
  title
  perex
  seoName
  image {
    ...File
  }
  imageAlt
  imageSource
  author
  publishedAt
  updatedAt
  redirectTo
}
    ${FileFragmentDoc}`;
export const ArticleCategoryFragmentDoc = gql`
    fragment ArticleCategory on ArticleCategory {
  id
  name
  titleSuffix
  articles {
    ...ArticlePreview
  }
  vertical
  subVertical
  metaTitle
  metaDescription
  metaImage {
    ...File
  }
}
    ${ArticlePreviewFragmentDoc}
${FileFragmentDoc}`;
export const ArticleTagFragmentDoc = gql`
    fragment ArticleTag on ArticleTag {
  tagId
  tagName
  tagSeoName
  articles {
    ...ArticlePreview
  }
}
    ${ArticlePreviewFragmentDoc}`;
export const BlogSectionTextFragmentDoc = gql`
    fragment BlogSectionText on BlogSectionText {
  id
  order
  sectionType
  contentSerialized
}
    `;
export const BlogSectionInternetSpeedFragmentDoc = gql`
    fragment BlogSectionInternetSpeed on BlogSectionInternetSpeed {
  id
  order
  title
  sectionType
  contentSerialized
}
    `;
export const BlogSectionTableFragmentDoc = gql`
    fragment BlogSectionTable on BlogSectionTable {
  id
  order
  sectionType
  content
  rows
  columns
}
    `;
export const BlogSectionBannerWithButtonFragmentDoc = gql`
    fragment BlogSectionBannerWithButton on BlogSectionBannerWithButton {
  id
  order
  sectionType
  contentSerialized
  title
  buttonText
  buttonUrl
  campaign {
    ...Campaign
  }
  bannerType: type
}
    ${CampaignFragmentDoc}`;
export const BlogSectionBannerWithCallbackFragmentDoc = gql`
    fragment BlogSectionBannerWithCallback on BlogSectionBannerWithCallback {
  id
  order
  sectionType
  contentSerialized
  title
  buttonText
  campaign {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;
export const BlogSectionYoutubeFragmentDoc = gql`
    fragment BlogSectionYoutube on BlogSectionYoutube {
  id
  order
  sectionType
  url
}
    `;
export const BlogSectionVehicleInsuranceContractDismissalTemplateFormFragmentDoc = gql`
    fragment BlogSectionVehicleInsuranceContractDismissalTemplateForm on BlogSectionVehicleInsuranceContractDismissalTemplateForm {
  id
  order
  sectionType
  title
  contentSerialized
}
    `;
export const BlogSectionSetriCeleCeskoFragmentDoc = gql`
    fragment BlogSectionSetriCeleCesko on BlogSectionSetriCeleCesko {
  id
  order
  sectionType
  setriCeleCesko
}
    `;
export const ArticleFragmentDoc = gql`
    fragment Article on Article {
  id
  category {
    id
    name
    vertical
    subVertical
  }
  title
  titleSuffix
  metaTitle
  perex
  seoName
  metaDescription
  image {
    ...File
  }
  imageAlt
  imageSource
  author
  publishedAt
  vertical
  subVertical
  knowledgeBaseConceptTags
  knowledgeBaseQuestionTags
  tags
  redirectTo
  sections {
    ... on BlogSectionText {
      ...BlogSectionText
    }
    ... on BlogSectionInternetSpeed {
      ...BlogSectionInternetSpeed
    }
    ... on BlogSectionTable {
      ...BlogSectionTable
    }
    ... on BlogSectionBannerWithButton {
      ...BlogSectionBannerWithButton
    }
    ... on BlogSectionBannerWithCallback {
      ...BlogSectionBannerWithCallback
    }
    ... on BlogSectionYoutube {
      ...BlogSectionYoutube
    }
    ... on BlogSectionVehicleInsuranceContractDismissalTemplateForm {
      ...BlogSectionVehicleInsuranceContractDismissalTemplateForm
    }
    ... on BlogSectionSetriCeleCesko {
      ...BlogSectionSetriCeleCesko
    }
  }
}
    ${FileFragmentDoc}
${BlogSectionTextFragmentDoc}
${BlogSectionInternetSpeedFragmentDoc}
${BlogSectionTableFragmentDoc}
${BlogSectionBannerWithButtonFragmentDoc}
${BlogSectionBannerWithCallbackFragmentDoc}
${BlogSectionYoutubeFragmentDoc}
${BlogSectionVehicleInsuranceContractDismissalTemplateFormFragmentDoc}
${BlogSectionSetriCeleCeskoFragmentDoc}`;
export const BlogSectionFragmentDoc = gql`
    fragment BlogSection on BlogSection {
  ... on BlogSectionText {
    ...BlogSectionText
  }
  ... on BlogSectionInternetSpeed {
    ...BlogSectionInternetSpeed
  }
  ... on BlogSectionTable {
    ...BlogSectionTable
  }
  ... on BlogSectionBannerWithButton {
    ...BlogSectionBannerWithButton
  }
  ... on BlogSectionBannerWithCallback {
    ...BlogSectionBannerWithCallback
  }
  ... on BlogSectionYoutube {
    ...BlogSectionYoutube
  }
  ... on BlogSectionVehicleInsuranceContractDismissalTemplateForm {
    ...BlogSectionVehicleInsuranceContractDismissalTemplateForm
  }
  ... on BlogSectionSetriCeleCesko {
    ...BlogSectionSetriCeleCesko
  }
}
    ${BlogSectionTextFragmentDoc}
${BlogSectionInternetSpeedFragmentDoc}
${BlogSectionTableFragmentDoc}
${BlogSectionBannerWithButtonFragmentDoc}
${BlogSectionBannerWithCallbackFragmentDoc}
${BlogSectionYoutubeFragmentDoc}
${BlogSectionVehicleInsuranceContractDismissalTemplateFormFragmentDoc}
${BlogSectionSetriCeleCeskoFragmentDoc}`;
export const SearchResultFragmentDoc = gql`
    fragment SearchResult on SearchResult {
  contentResults {
    ... on ArticlePreview {
      ...ArticlePreview
    }
  }
  titleResults {
    ... on ArticlePreview {
      ...ArticlePreview
    }
  }
  mixedUniqueResults {
    ... on ArticlePreview {
      ...ArticlePreview
    }
  }
  contentResultsCount
  titleResultsCount
  mixedUniqueResultsCount
}
    ${ArticlePreviewFragmentDoc}`;